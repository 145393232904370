.loadingScreen {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #fff;
}

.loadingScreen .loadingDot {
	font-size: 80px;
	margin-top: -60px;
	position: relative;
	animation: ease-in-out infinite alternate;
	animation-name: run;
	animation-duration: 1s;
}

@keyframes run {
	0% {
		left: -50px;
		color: #a5bcc3;
	}

	50% {
		color: #5c7990;
	}

	100% {
		left: 50px;
		color: #a5bcc3;
	}
}
