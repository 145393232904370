body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
	font-family: "Lato", "Helvetica", "Arial", sans-serif !important;
	color: #3e4b59;
}

.formGroup {
	border: 1px solid #a5bcc3;
	padding: 10px;
	background-color: #f8f9fa;
	margin-bottom: 1rem;
	border-radius: 5px;
}

.donationAmount {
	text-align: center;
	font-size: 2rem;
	font-weight: 400;
}

.successBox {
	color: #fff;
	text-align: center;
	background-color: #0f8a5f;
}

.successBox h1 {
	color: #fff;
}

.successBox h2 {
	color: #fff;
}

.PhoneInputInput {
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	padding: 0.375rem 0.75rem;
	height: calc(1.5em + 0.75rem + 2px);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	color: #495057;
}
.PhoneInput:focus {
	border: 1px solid #ced4da;
}

.react-datepicker-popper {
	z-index: 9999 !important;
}

img.avatar {
	object-fit: cover;
	width: 40px;
	height: 40px;
}

input:focus,
.StripeElement--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

.StripeElement {
	display: block;
	max-width: 500px;
	padding: 10px 14px;
	font-size: 1em;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border: 0;
	outline: 0;
	border-radius: 4px;
	background: white;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
	padding: 0;
}

.dropdown-menu.show {
	max-height: 100vh; /* Adjust the height as needed */
	overflow-y: auto;
}
